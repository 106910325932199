<template lang="pug">
.map-wrapper
  LMap(:zoom="zoom", :center="center")
    LTileLayer(:url="url" :subdomains="subdomains")
    LMarker(v-for="item in markers", :key="item.name", :lat-lng="item.latlng")
      LPopup 
        a(:href="`${item.link}`") {{ item.name }}
      LIcon.map-icon(
        :iconUrl="require(`../assets/icons/scarlett-marker.svg`)",
        :iconSize="iconSize"
      )
</template>
<script>
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
} from "@vue-leaflet/vue-leaflet";

export default {
  name: "geoMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
  },
  data() {
    return {
      url: "https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
      zoom: 4,
      center: [59.217862, 39.896554],
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
      markers: [
        {
          name: "СпецСтрой Технология",
          link: "https://ssta.ru/",
          latlng: [59.9174455, 30.3250575],
        },
        {
          name: "Гостевой дом",
          link: "https://29-a.ru/",
          latlng: [44.8862299, 37.3327389],
        },
        {
          name: "ТСЖ/УК - Сервис",
          link: "https://tsg.1mcg.ru/",
          latlng: [55.8247489, 37.3078212],
        },
        {
          name: "МО Тельвисочный сельсовет",
          link: "https://adm-telwiska.ru/",
          latlng: [67.6569035, 53.0586145],
        },
        {
          name: "МБДОУ г. Мурманска №157",
          link: "https://mbdou157.1mcg.ru/",
          latlng: [68.9675794, 33.0857923],
        },
        {
          name: "Школа №1 г. Котлас",
          link: "https://1school.1mcg.ru/",
          latlng: [61.227456, 46.6683245],
        },
        {
          name: "Няндомский железнодорожный колледж",
          link: "https://ngk29.ru/",
          latlng: [61.0619235, 42.1196761],
        },
        {
          name: "Санаторий «Шафраново» \n (рекламная компания)",
          link: "https://shafranovo.ru/",
          latlng: [54.0523729, 56.5804735],
        },
        {
          name: "Priamo \n (Женская одежда)",
          link: "https://priamo52.ru/",
          latlng: [56.29274, 43.9267451],
        },
        {
          name: 'Центр судоремонта "Звёздочка"',
          link: "https://www.star.ru/",
          latlng: [64.5688399, 39.836466],
        },
        {
          name: '"Тверской химико-технологический колледж"',
          link: "https://thtk.1mcg.ru/",
          latlng: [56.8544242, 35.9081812],
        },
        {
          name: '"Дагестанская правда"',
          link: "https://d.1mcg.ru/",
          latlng: [42.9778351, 47.4892424],
        },
        {
          name: "Инвестиционный портал города Кемерово",
          link: "https://ip.kemerovo.ru/",
          latlng: [55.3785765, 86.0627768],
        },
        {
          name: '"ГБУЗ ЦМСЧ №71 ФМБА России',
          link: "https://cmsch71.1mcg.ru/",
          latlng: [55.71381, 60.7009592],
        },
        {
          name: "Северное речное пароходство",
          link: "https://nrsl.ru/",
          latlng: [57.1612975, 65.5250172],
        },
        {
          name: "Пермское государственное хореографическое училище",
          link: "https://balletperm.ru",
          latlng: [58.0296813, 56.2667916],
        },
        {
          name: "Гимназия г. Надым",
          link: "https://nadym.1mcg.ru",
          latlng: [65.535603, 72.504408],
        },
        {
          name: "СОШ Сортавала",
          link: "https://sortavala.1mcg.ru",
          latlng: [61.704558, 30.686337],
        },
        {
          name: 'ООО "Стальэнерго"',
          link: "https://sks-tv.ru/",
          latlng: [59.217862, 39.896554],
        },
        {
          name: 'КСК "ЯМАЛ" Установка CMS ',
          link: "https://kskyamal.ru/",
          latlng: [63.183812, 75.44769],
        },
        {
          name: 'ФБУ "Авиалесохрана" Установка CMS ',
          link: "https://aviales.1mcg.ru/",
          latlng: [55.999644, 37.829852],
        },
        {
          name: "Средне-Невский Судостроительный Завод",
          link: "https://snsz.ru/",
          latlng: [59.932214, 30.359816],
        },
        {
          name: 'ГОУП "Учебно-спортивный центр" ',
          link: "https://uscenter.ru/",
          latlng: [68.958086, 33.076123],
        },
        {
          name: "ТРИК ФАРМА ",
          link: "https://trik.ru/",
          latlng: [45.039696, 38.9824],
        },
        {
          name: "«Нижневартовский медицинский колледж» ",
          link: "https://mednv.1mcg.ru/",
          latlng: [60.958296, 76.569686],
        },
        {
          name: "«Национальный парк Югыд ва» ",
          link: "https://yugyd-va.ru/",
          latlng: [64.743782, 59.389988],
        },
        {
          name: "«Салехардский центр молодежи» ",
          link: "https://citymolod.ru/",
          latlng: [66.544129, 66.58362],
        },
        {
          name: "«Мончегорский дом-интернат» ",
          link: "https://mdiuod.ru/",
          latlng: [67.980635, 32.86863],
        },
        {
          name: "«Школа иностранных языков» \n (Интернет касса)",
          link: "https://pay.abc-studio-yar.ru/",
          latlng: [57.626931, 39.882948],
        },
        {
          name: "«Сыктывкарский целлюлозно-бумажный техникум»",
          link: "https://scbt.1mcg.ru/",
          latlng: [61.805576, 50.736574],
        },
        {
          name: "«Векора»",
          link: "https://vekora.ru/",
          latlng: [44.7136127501254, 37.764954223873104],
        },
        {
          name: "«Sustainability of Architectural Heritage»",
          link: "https://sah.1mcg.ru/",
          latlng: [42.351531760284786, 13.397226687048457],
        },
        {
          name: "«МУП РАО»",
          link: "https://muprao.ru/",
          latlng: [55.579174, 42.052411],
        },
        {
          name: "Санаторий «Выборг-3»",
          link: "https://vyborg-3.ru/",
          latlng: [60.710496, 28.749781],
        },
        {
          name: "Аптека «Гедеон»",
          link: "https://gedeon.1mcg.ru/",
          latlng: [54.710162, 20.510137],
        },
        {
          name: "Стерлитомакский колледж ЦОК",
          link: "https://websm.io/",
          latlng: [53.630403, 55.930825],
        },
        {
          name: "Кукоморский колледж,\n профессионалитет разработка",
          link: "https://websm.io/",
          latlng: [56.253447, 50.578519],
        },
        {
          name: "Новоспасский техникум \n Цифровой Образовательный Контент",
          link: "https://websm.io/",
          latlng: [53.147450, 47.765759],
        },
      ],
    };
  },
  computed: {
    iconSize() {
      return [20, 25];
    },
  },
};
</script>
