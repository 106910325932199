<template lang="pug">
.footer
  .footer-contacts-main
    .footer-contacts-main-wrapper(v-for="contactsMain in mainContacts" :key="contactsMain.title")
      .footer-contacts-main-subtitle {{ contactsMain.title }}
      .footer-contacts-main-title {{ contactsMain.contact }}
  .footer-contacts-sub
    .footer-contacts-sub-wrapper(v-for="contactsSub in subContacts" :key="contactsSub.title")
      .footer-contacts-sub-title {{ contactsSub.title }}
      .footer-contacts-sub-contact {{ contactsSub.contact }}
        //- router-link.footer-contacts-socials(v-for="social in contactsSub.socials" :key="social.name" :to="`${social.link}`")
        //-   img.footer-contacts-socials-img(:src="require(`../assets/icons/${social.icon}`)" :alt="`${social.name}`")
  .footer-tempcontacts
    .footer-tempcontacts-sub-wrapper(v-for="contactsGroup in groupContacts" :key="groupContacts.title")
        .footer-tempcontacts-title {{ contactsGroup.title }}
    .twocolumns
      .column
        .footer-tempcontacts-sub-wrapper(v-for="contactsEntry in groupContacts[1].column1" :key="groupContacts[1].column1")
          .footer-tempcontacts-sub-title {{ contactsEntry.title }}
          .footer-tempcontacts-sub-contact {{ contactsEntry.contact }}
      .column
        .footer-tempcontacts-sub-wrapper(v-for="contactsEntry in groupContacts[1].column2" :key="groupContacts[1].column2")
          .footer-tempcontacts-sub-title {{ contactsEntry.title }}
          .footer-tempcontacts-sub-contact {{ contactsEntry.contact }}

       
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      mainContacts: [
        { title: "Напишите нам", contact: "commerce@websm.io" },
        { title: "Звонок по России бесплатный", contact: "8-800-200-27-77" },
      ],
      subContacts: [
        { title: "Техническая поддержка", contact: "support@websm.io"},
        { title: "Отдел разработки", contact: "developer@websm.io \n  \n "},
        { title: "Адрес", contact: "163060, г. Архангельск,  \n А/Я 15"},
        { title: "", contact: ""},
        { socials: [
          { name: "Facebook", icon: "facebook.svg", link: "#" },
          { name: "Instagram", icon: "instagram.svg", link: "#" },
          { name: "Vk", icon: "vk.svg", link: "#" },
          { name: "Twitter", icon: "twitter.svg", link: "#" },
          { name: "Youtube", icon: "youtube.svg", link: "#" },
        ]}
      ],
      groupContacts: [
        {title: "Группа компаний Скарлетт и Монарх"},
        { column1: [
          { title: "", contact: "ООО «Скарлетт и Монарх»"},
          { title: "", contact: "ИНН: 2901248177"},
          { title: "", contact: "Юридический адрес: г. Архангельск, пр. Ломоносова, д. 135, помещ. 20-Н"},
          { title: "", contact: "Почтовый адрес: 163060, г. Архангельск, а/я 15"},
          { title: "", contact: "Телефон: 8-800-200-27-77"},
        ],
         column2: [
          { title: "", contact: "ООО «Монарх»"},
          { title: "", contact: "ИНН: 2901304255"},
          { title: "", contact: "Юридический адрес: г. Архангельск, ул. Адмирала Кузнецова, д. 11, кв. 82"},
          { title: "", contact: "Почтовый адрес: 163060, г. Архангельск, а/я 15"},
          { title: "", contact: "Телефон: 8-800-200-27-77"},
        ],
        }
      ]
    }
  },
}
</script>
